import * as React from "react"
import { Suspense } from "react"
import { Link } from 'gatsby'
import Layout from '../components/layout'
//import component list
import EbookLanding from '../components/ebook-landing'
import LoadingSpinner from '../components/_loading-spinner'

const EbookForm = React.lazy(() => import('../components/ebook-form'))
const CenteredQuote = React.lazy(() => import('../components/centered-quote'))
const GlobalDuplexBanner = React.lazy(() => import('../components/global-duplex-banner'))
const VideoFeature = React.lazy(() => import('../components/video-focus'))

const ContentArea = ({layoutPieces}) => {
    if (layoutPieces) {
      return layoutPieces.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulEBookLanding' && <EbookLanding layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulEBookForm' && <Suspense key={index} fallback={<LoadingSpinner />}><EbookForm layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulCenteredQuote' && <Suspense key={index} fallback={<LoadingSpinner />}><CenteredQuote layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulDuplexFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><GlobalDuplexBanner layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulVideoFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><VideoFeature layoutPiece={layoutPiece} /></Suspense>,
        ])
      })
    }
}

export default ContentArea
