import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
//import component pieces
import EBookContentArea from '../templates/ebook-content-area'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function EbookPage({ data, pageContext }) {
  const layoutPieces = data.contentfulEBookPageTemplate.pageComponents

  return (
    <Layout header={data?.contentfulEBookPageTemplate?.header} footer={data?.contentfulEBookPageTemplate?.footer}>
      <EBookContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta name="description" content={data?.contentfulEBookPageTemplate?.metaDescription} />
    <title>{data?.contentfulEBookPageTemplate?.metaTitle}</title>
    <meta property="og:title" content={data?.contentfulEBookPageTemplate?.ogMetaTitle ? data.contentfulEBookPageTemplate.ogMetaTitle : data?.contentfulEBookPageTemplate?.metaTitle} />
    <meta property="og:description" content={data?.contentfulEBookPageTemplate?.ogMetaDescription ? data.contentfulEBookPageTemplate.ogMetaDescription : data?.contentfulEBookPageTemplate?.metaDescription} />
    <meta property="og:type" content={data?.contentfulEBookPageTemplate?.ogMetaType} />
    <meta property="og:url" content={data?.contentfulEBookPageTemplate?.slug ? `https://www.entrata.com${data?.contentfulEBookPageTemplate?.slug}` : undefined} />
    <meta property="og:image" content={data?.contentfulEBookPageTemplate?.ogMetaImage?.image ? data?.contentfulEBookPageTemplate?.ogMetaImage?.image?.url : ogMetaImage} />
    <link rel="stylesheet" href="https://use.typekit.net/dbl1hid.css" />
  </>
)

export const query = graphql`
  query EbookComponentPieces($slug: String!) {
    contentfulEBookPageTemplate(slug: { eq: $slug }) {
      slug
      header
      footer
      metaDescription
      metaTitle
      ogMetaTitle
      ogMetaDescription
      ogMetaType
      ogMetaImage {
        image {
          url
        }
      }
      pageComponents {
        ... on Node {
          internal {
            type
          }
          ... on ContentfulEBookLanding {
            ...EbookLanding
          }
          ... on ContentfulEBookForm {
            ...EbookForm
          }
          ... on ContentfulPortfolioCarousel {
            ...ClientFeature
          }
          ... on ContentfulCenteredQuote {
            ...CenteredQuote
          }
          ... on ContentfulDuplexFeature {
            ...DuplexFeature
          }
          ... on ContentfulVideoFeature {
            ...VideoFocus
          }
        }
      }
    }
  }
`
