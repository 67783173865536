import React from 'react'
import { Link, graphql } from 'gatsby'
import '../styles/ebook-landing.css'
import LinkArrowLight from '../assets/link-arrow-light.svg'
import LinkArrow from '../assets/link-arrow.svg'
import ReactMarkdown from 'react-markdown'
import WistiaLink from './wistia-link'
import { symbolResize, checkSvg } from '../helper-functions'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function EbookLanding({ layoutPiece }) {
  const gatsbyImage = getImage(layoutPiece?.image?.image)
  const image = layoutPiece?.image?.image
  const button = layoutPiece?.button 
  const link = layoutPiece?.link 

  function GetBackgroundColor() {
    switch (layoutPiece.backgroundColor) {
      case 'Black':
        return "ebook-hero dark-background background-hero-spacing"
      case 'Grey':
        return "ebook-hero grey-background background-hero-spacing"
      case 'Off-white':
        return "ebook-hero eggshell-background background-hero-spacing"
      default:
        return "ebook-hero background-hero-spacing"
    }
  }

  return (
    <div className={GetBackgroundColor()}>
      <div className="content-cap">
        <div className="product-landing-grid ebook-grid">

        {
          layoutPiece?.textAlignment === 'Text Right' ?
            <div className={layoutPiece?.image?.fitToFrame ? "product-image image-contain left-image" : "product-image left-image"}>
            {
              layoutPiece?.image ?
                checkSvg(image?.url) ?
                  <img src={image?.url} alt={image?.description} className="product-image-file" />
                  :
                  <GatsbyImage image={gatsbyImage} alt={image?.description} className="product-image-file" />
                : ""
            }
            </div>
          :
          ""
        }

          <div className="product-text-align">
            <div className="product-text">
              <h1>{layoutPiece?.headlineStandardText}</h1><span className="ebook-alt-text">{layoutPiece?.headlineAltText}</span>
              <ReactMarkdown className="markdown">{layoutPiece?.bodyText?.bodyText}</ReactMarkdown>
              <div className="button-holder">
                {
                  button && button?.fullUrl && layoutPiece?.backgroundColor === 'Black' ?
                    <a className="button-default solid-light-button" href={button?.fullUrl}>{button?.buttonText}</a>
                  :
                  button && button?.fullUrl ?
                    <a className="button-default solid-dark-button" href={button?.fullUrl}>{button?.buttonText}</a>
                  : ""
                }
                {
                  button && button?.slug && layoutPiece?.backgroundColor === 'Black' ?
                    <Link className="button-default solid-light-button" to={button?.slug}>{button?.buttonText}</Link>
                  :
                  button && button?.slug ?
                    <Link className="button-default solid-dark-button" to={button?.slug}>{button?.buttonText}</Link>
                  : ""
                }
                {
                  link && link?.fullUrl && layoutPiece?.backgroundColor === 'Black' ?
                    <a className="cta-link-default light-cta-link banner-link" href={layoutPiece.link.fullUrl}>{layoutPiece.link.text}<span className="link-arrow-icon"><LinkArrowLight /></span></a>
                  :
                  link && link?.fullUrl ?
                    <a className="cta-link-default dark-cta-link banner-link" href={link?.fullUrl}>{link?.text}<span className="link-arrow-icon"><LinkArrow /></span></a>
                  : ""
                }
                {
                  link?.slug && layoutPiece?.backgroundColor === 'Black' ?
                    <Link className="cta-link-default light-cta-link banner-link" to={link?.slug}>{link?.text}<span className="link-arrow-icon"><LinkArrowLight /></span></Link>
                  :
                  link && link?.slug ?
                    <Link className="cta-link-default dark-cta-link banner-link" to={link?.slug}>{link?.text}<span className="link-arrow-icon"><LinkArrow /></span></Link>
                  : ""
                }
              </div>
            </div>
          </div>

          {
            layoutPiece?.textAlignment === 'Text Left' ?
              <div className={layoutPiece?.image?.fitToFrame ? "product-image image-contain right-image" : "product-image right-image"}>
              {
                layoutPiece?.image ?
                  checkSvg(image?.url) ?
                    <img src={image?.url} alt={image?.description} className="product-image-file" />
                    :
                    <GatsbyImage image={gatsbyImage} alt={image?.description} className="product-image-file" />
                  : ""
              }
              </div>
            :
            ""
          }

        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment EbookLanding on ContentfulEBookLanding {
    id
    backgroundColor
    headlineStandardText
    headlineAltText
    textAlignment
    bodyText {
      bodyText
    }
    image {
      fitToFrame
      image {
        gatsbyImageData(width: 900, placeholder: BLURRED)
        url
        description
      }
    }
    button {
      buttonOutlined
      buttonText
      fullUrl
      slug
    }
    link {
      text
      fullUrl
      slug
    }
  }
`
